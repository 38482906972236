import React from "react"
import SEO from "../components/SEO"

import Layout from "../components/Layout"
import { graphql } from "gatsby"
import ArticleToEtsyButton from "../components/ArticleToEtsyButton"
import ArticleDescription from "../components/ArticleDescription"
import ArticleTags from "../components/ArticleTags"
import ArticleImages from "../components/ArticleImages"

export default function Product({ data, location }) {
  return (
    <Layout>
      <SEO
        title={data.storeListing.title}
        description={data.storeListing.description.split("\n\n")[0]}
        pathname={location.pathname}
        image={data.storeListing.images[0].childImageSharp.fluid}
      />
      <section className="mt-4">
        <div className="container">
          <h1 className="title">{data.storeListing.title}</h1>
          <div className="columns">
            <div className="column is-one-third">
              <ArticleImages article={data.storeListing} />
            </div>
            <div className="column">
              <ul>
                <li className="mb-2">
                  <ArticleToEtsyButton article={data.storeListing} />
                </li>
                <li className="mb-2">
                  <ArticleDescription article={data.storeListing} />
                </li>
                <li className="mb-2">
                  <ArticleTags
                    title="Materialien"
                    field={data.storeListing.materials}
                    tagStyle="is-primary"
                  />
                </li>
                <li className="mb-2">
                  <ArticleTags title="Tags" field={data.storeListing.tags} />{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }

    storeListing(fields: { slug: { eq: $slug } }) {
      title
      id
      section
      currency_code
      description
      price
      quantity
      sku
      state
      materials
      tags
      title
      url
      images {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
          resize(width: 1200) {
            src
            height
            width
          }
        }
      }
    }
  }
`
