import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "../utils/font-awesome"

export default function ArticleToEtsyButton({ article }) {
  return (
    <div>
      <strong>Zum Artikel im Etsy-Store</strong>
      <div className="field has-addons">
        <p className="control">
          <span className="button is-static">
            {article.price} {article.currency_code}
          </span>
        </p>
        <div className="control">
          <a
            className="button is-success"
            disabled={article.state !== "active"}
            href={article.state === "active" ? article.url : "#"}
          >
            <span className="mr-2">
              <FontAwesomeIcon icon={["fab", "etsy"]} />
            </span>
            Artikel anzeigen
          </a>
        </div>
      </div>
    </div>
  )
}
