import React from "react"

import ImageGallery from "react-image-gallery"

export default function ArticleImages({ article }) {
  const stateToName = {
    removed: "Verkauft",
    sold_out: "Verkauft",
    edit: "Vorschau",
    draft: "Vorschau",
  }

  const images = article.images.map(image => {
    return {
      original: image.childImageSharp.resize.src,
      thumbnail: image.childImageSharp.fluid.src,
    }
  })

  const moreThanOneImage = images.length > 1

  return (
    <div className="imageContainer">
      {Object.keys(stateToName).includes(article.state) && (
        <h4 className="ribbon">{stateToName[article.state]}</h4>
      )}

      <ImageGallery
        items={images}
        showNav={moreThanOneImage}
        showThumbnails={moreThanOneImage}
        showPlayButton={moreThanOneImage}
      />
    </div>
  )
}
