import React from "react"

export default function ArticleTags({ title, field, tagStyle }) {
  var classes = `tag ${tagStyle}`
  return (
    <div>
      <strong>{title}</strong>
      <div className="tags">
        {field.map(tag => (
          <span key={tag} className={classes}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  )
}
